<template>
    <div>
        <div class="is-pulled-right mb-2"><input class="input" type="date" v-model="end_date" /></div>
        <div class="is-pulled-right mb-2"><input class="input" type="date" v-model="start_date" /></div>
        <button @click="exportXLS()" class="button is-link is-pulled-right mr-2" :disabled="loading==true">Export For Excel</button>
        <h2 class="title">Booking Types/Dates Report</h2>

       
        <div class="notification is-warning" v-if="loading == true">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false && bookings.length == 0">
            No active bookings can be found 
        </div>

        <table class="table is-fullwidth is-hoverable" v-if="loading == false && this.bookings.length > 0" id="bookingstable">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Client</th>
                    <th>Grade</th>
                    <th>Day</th>
                    <th>Start Date</th>
                    <th>Start Time</th>
                    <th>End Date</th>
                    <th>End Time</th>
                    <th>Holiday</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in this.bookings" :key="row.id" >
                    <td>{{ row.id }}</td>
                    <td>{{ row.client_name }}</td>
                    <td>{{  row.grade_code }}</td>
                    <td>{{  getDayOfWeek(new Date(row.start_date)) }}</td>
                    <td>{{ row.actual_start_date }}</td>
                    <td>{{ row.actual_start_time }}</td>
                    <td>{{ row.actual_end_date }}</td>
                    <td>{{ row.actual_end_time }}</td>
                    <td><span v-if="hasHolidayInvoice(row)">Holiday</span></td>
                </tr>
                
                
            </tbody>
            
        </table>

       
    </div>
</template>

<script>
import * as XLSX from 'xlsx'
import UtilityService from '@/services/UtilityService.js';


//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'BookingDates',
        mixins: [],
        components: {
            //MemberGradeTags
        },
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                  
                   showModal: false,
                   order_by: "actual_start_date_time ASC",
                   start_date: '',
                   end_date: '',
                   accessToken: ''
            }
        },
        methods: {
            exportXLS() {
		
                var wb = XLSX.utils.table_to_book(document.getElementById('bookingstable'), {sheet:"Sheet JS"});
                XLSX.writeFile(wb, 'Bookings_Report.xlsx')

            },
            hasHolidayInvoice(booking) {
                return booking.invoices.some(invoice => invoice.day === "holiday");
            },
            getDayOfWeek(date) {

                const dayIndex = date.getDay(); // Returns 0 (Sunday) to 6 (Saturday) [1, 2, 3]

                const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

                return dayNames[dayIndex];

            },
            getStartDate() {
                var d = new Date();
                d.setDate(d.getDate() - 180);
                return d.toISOString().slice(0,10);
            },
            getEndDate() {
                var d = new Date();
                d.setDate(d.getDate() );
                return d.toISOString().slice(0,10);
            },
            async getBookings() {
                this.loading=true
                if(this.accessToken != '' && this.start_date != '' && this.end_date != '')
                {
                    UtilityService.getSimpleApiData(this.accessToken, "bookings/processed?start_date=" + this.start_date + "&end_date=" + this.end_date)
                    .then(
                        (bookings => {
                            this.$set(this, "bookings", bookings);
                            this.loading=false;
                            this.working = false;
                        }).bind(this)
                    );
                    } else {
                        this.working = false;
                    }
            },
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getBookings()
            });
        
        },
        watch: {
            start_date: function () {
                localStorage.booking_dates_start_date = this.start_date;
                //alert(this.start_date + " " + this.end_date)
                if(this.accessToken != '')
                {
                this.bookings = []
                
                this.getBookings();
                }
            },
            end_date: function () {
                localStorage.booking_dates_end_date = this.end_date;
                //alert(this.start_date + " " + this.end_date)
                if(this.accessToken != '')
                {
                    this.bookings = []
                    
                    this.getBookings();
                }
            },
        
        },
        mounted() {
            if (localStorage.booking_dates_start_date) {
                this.start_date = localStorage.booking_dates_start_date; 
            }
            else{
                this.start_date = this.getStartDate()
            }

            if (localStorage.booking_dates_end_date) {
                this.end_date = localStorage.booking_dates_end_date;
            }
            else{
                this.end_date =this.getEndDate()
            }

           
            
        },
    }
</script>